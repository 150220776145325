import { fb, validators } from "../../../form";
import utils from "../../../utils";

export function getShippingFormData(cart) {
  const data = cart || {};

  const shippingMethods = [
    { name: "Shipping", value: "Standard" },
    { name: "Pickup", value: "Pickup" },
  ];

  const shippingCountry = [
    { name: "Australia", value: "Australia" },
    { name: "International", value: "International" },
  ];

  // const shippingOptionList = [
  //   //{ name: 'Valentines Day $20 (delivery between 8am-6pm)', value: "valentines" },
  //   { name: '$20 Standard Same Day Delivery', value: "standard", helpText : '(between 9-6pm for orders placed before 2pm)' },
  //   { name: '$20 Business Address Same Day Delivery', value: "business", helpText : '(between 9-6pm for orders placed before 2pm)' },
  //   { name: '$20 Funeral Timed Delivery (please specify funeral time in notes)', value: "funeral" },
  //   { name: '$35 Express Delivery Monday - Saturday', value: "express", helpText : '(Delivery within 3 hours of order being received, <span style="color:red;"> not available on Valentine’s Day </span>)' },
  //   { name: 'Mothers Day Sunday $35 (Delivery between 8am-5pm)', value: "mothers_sunday" }
  // ];

  const shippingOptionList = [
    //{ name: 'Valentines Day $20 (delivery between 8am-6pm)', value: "valentines" },
    {
      name: "Standard - for same day or future delivery. Delivery will be between 9am-6pm.",
      value: "standard",
    },
    { name: 'Valentines Day - standard delivery between 7am-7pm on 14.2.25', value: "valentines" },
    {
      name: "Funeral - please give service time in the notes to shop.",
      value: "funeral",
    },
    {
      name: "Business - please give a business name and business’s opening hours in the notes to shop.",
      value: "business",
    },
    {
      name: "Express - for delivery within 3 hours of your order being received OR for future deliveries with time restraints. Additional $20 on standard shipping rate.",
      value: "express",
      helpText: "NOT AVAILABLE FOR VALENTINES DAY 14.2.2025"
    },
    // {
    //   name: "Mothers Day Sunday Delivery - additional $20 on standard shipping rate.",
    //   value: "mothers_sunday",
    // },
  ];

  const preferredDelivertyPickupDateList = [
    { name: "Today", value: "Today" },
    { name: "Tomorrow", value: "Tomorrow" },
    { name: "Other", value: "Other" },
  ];

  // let postCodeList = data.postCodes.map((x) => {
  //   return { name : `${x.suburb}-${x.postcode}-Shipping fee: ${x.fee}`, value : `${x.suburb}|${x.postcode}`}
  // });

  let postCodeList = data.postCodes.map((x) => {
    return {
      name: `${x.suburb}, ${x.postcode} - Shipping fee: $${x.fee}`,
      value: `${x.suburb}|${x.postcode}`,
    };
  });

  postCodeList = [{ name: "Select*", value: "" }, ...postCodeList];

  const model = fb.group({
    billingInfo: address2(data.billingAddress, postCodeList),
    shippingInfo: address(data.shippingAddress, postCodeList),
    //shippingMethod: [data.shippingMethod || '', [], { label: 'Shipping Method', type: 'text' }],
    shippingMethod: [
      data.shippingMethod || "",
      [],
      {
        label: null,
        type: "radioList",
        options: shippingMethods,
        style: { inline: true },
      },
    ],
    shippingCountry: [
      data.shippingCountry || "Australia",
      [],
      {
        label: null,
        type: "radioList",
        options: shippingCountry,
        style: { inline: true },
      },
    ],
    useSameAddress: [
      data.useSameAddress || false,
      [],
      { label: `Use the same address`, type: "checkbox" },
    ],
    shippingOption: [
      data.shippingOption || "standard",
      [validators.Required()],
      {
        label: "Shipping Options",
        helpLabelText:
          "(same day delivery available Monday - Saturday for orders placed before 1pm)",
        type: "radioList",
        options: shippingOptionList,
        style: { className: "cart__shipping__option" },
      },
    ],
    preferredDelivertyPickupDate: [
      data.preferredDelivertyPickupDate || "Tomorrow",
      [validators.Required()],
      {
        label: "Preferred Delivery/Pickup Date",
        type: "radioList",
        options: preferredDelivertyPickupDateList,
        style: { className: "cart__deliverty__pickup__date", inline: true },
      },
    ],
    //preferredDelivertyPickupOtherDate: [data.preferredDelivertyPickupOtherDate || '', [], { label: 'Please specify other date', type: 'text', style : {className : 'cart__deliverty__pickup__other__date'} }],

    preferredDelivertyPickupOtherDate: [
      data.preferredDelivertyPickupOtherDate || "",
      [],
      {
        label: "Please specify other date",
        type: "date",
        attrs: { min: utils.date.formatYYYYMMDDToday() },
        style: { className: "cart__deliverty__pickup__other__date" },
      },
    ],

    pickupTime: [
      data.pickupTime || "",
      [],
      {
        label: "Pickup Time",
        type: "text",
        style: { className: "cart__pickup__time" },
      },
    ],
    messageForCard: [
      data.messageForCard || "",
      [],
      {
        label: "Message For Card",
        type: "textarea",
        style: { className: "cart__message__for__card", rows: 3 },
      },
    ],
    notesToShop: [
      data.notesToShop || "",
      [],
      {
        label: "Notes To Shop",
        type: "textarea",
        style: { className: "cart__notes__to__shop", rows: 2 },
      },
    ],
  });
  return model;
}

export function address(model, postCodeList) {
  const data = model || {};

  const _suburbPostcode =
    data.suburb && data.suburb !== "" && data.postcode && data.postcode !== ""
      ? data.suburb + "|" + data.postcode
      : "";

  return fb.group({
    firstName: [
      data.firstName || "",
      [validators.Required()],
      { label: "First Name", type: "text" },
    ],
    surname: [
      data.surname || "",
      [validators.Required()],
      { label: "Surname", type: "text" },
    ],
    businessName: [
      data.businessName || "",
      [],
      { label: "Business Name (if applicable)", type: "text" },
    ],
    street1: [
      data.street1 || "",
      [validators.Required()],
      { label: "Address", type: "text" },
    ],
    street2: [data.street2 || "", [], { label: null, type: "text" }],
    //suburb: [data.suburb || '', [validators.Required()], { label: 'Suburb', type: 'text' }],
    //suburb: [data.suburb || '', [validators.Required()], { label: 'Suburb', type: 'select', options : postCodeList, helpText : "If your suburb is not listed here please contact the store on 8276 7370 to arrange your order"}],
    suburbPostcode: [
      _suburbPostcode || "",
      [validators.Required()],
      {
        label: "Suburb",
        type: "select",
        options: postCodeList,
        helpText:
          "If your suburb is not listed here please contact the store on 8276 7370 to arrange your order",
      },
    ],
    state: [
      data.state || "",
      [validators.Required()],
      { label: "State", type: "text" },
    ],
    //postcode: [data.postcode || '', [validators.Required()], { label: 'Postcode', type: 'text' }],
    //postcode: [data.postcode || '', [], { label: 'Postcode', type: 'text' }],
    //postcode: [data.postcode || '', [validators.Required()], { label: 'Postcode', type: 'select', options : postCodeList}],
    email: [
      data.email || "",
      [validators.Required(), validators.Email()],
      {
        label:
          "Customer’s (Your) Email Address - the invoice/receipt will be sent here",
        type: "email",
      },
    ],
    mobile: [
      data.mobile || "",
      [validators.Required()],
      { label: "Customer’s (Your) Phone Number", type: "tel" },
    ],
    recipientMobile: [
      data.recipientMobile || "",
      [],
      { label: "Recipient’s Phone Number", type: "tel" },
    ],
  });
}

export function address2(model) {
  const data = model || {};
  return fb.group({
    firstName: [
      data.firstName || "",
      [validators.Required()],
      { label: "First Name", type: "text" },
    ],
    surname: [
      data.surname || "",
      [validators.Required()],
      { label: "Surname", type: "text" },
    ],
    businessName: [
      data.businessName || "",
      [],
      { label: "Business Name (if applicable)", type: "text" },
    ],
    street1: [
      data.street1 || "",
      [validators.Required()],
      { label: "Address", type: "text" },
    ],
    street2: [data.street2 || "", [], { label: null, type: "text" }],
    suburb: [
      data.suburb || "",
      [validators.Required()],
      { label: "Suburb", type: "text" },
    ],
    //suburb: [data.suburb || '', [validators.Required()], { label: 'Suburb', type: 'select', options : postCodeList, helpText : "If your suburb is not listed here please contact the store on 8276 7370 to arrange your order"}],
    state: [
      data.state || "",
      [validators.Required()],
      { label: "State", type: "text" },
    ],
    postcode: [
      data.postcode || "",
      [validators.Required()],
      { label: "Postcode", type: "text" },
    ],
    //postcode: [data.postcode || '', [validators.Required()], { label: 'Postcode', type: 'select', options : postCodeList}],
    email: [
      data.email || "",
      [validators.Required(), validators.Email()],
      {
        label:
          "Customer’s (Your) Email Address - the invoice/receipt will be sent here",
        type: "email",
      },
    ],
    mobile: [
      data.mobile || "",
      [validators.Required()],
      { label: "Customer’s (Your) Phone Number", type: "tel" },
    ],
  });
}

// public AddressModel ShippingInfo { get; set; }
// public AddressModel BillingInfo { get; set; }
// public string ShippingMethod { get; set; }
// public bool UseSameAddress { get; set; }
// public string SpecialRequest { get; set; }

// public string FirstName { get; set; }
// public string Surname { get; set; }
// public string AddressLine { get; set; }
// public string Suburb { get; set; }
// public string State { get; set; }
// public string Postcode { get; set; }
// public string Country { get; set; }
// public string CountryCode { get; set; }
// public string Email { get; set; }
// public string Phone { get; set; }
// public string Mobile { get; set; }

// (if applicable)
